<template>
  <div>
    <div>
      <div class="container">
        <div class="bottom text-center">
          ©瑞讯银行
        </div>
      </div>
    </div>
  </div>

</template>
<script>
export default {
  name: 'top',
  props: ['menu'],
  data () {
    return {

    }
  },
  methods: {

  },
};
</script>
<style scoped>
.container {
  padding: 0px;
  position: relative;
}
.bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #ffffff;
  padding: 14px;
  color: #707070;
  font-size: 14px;
  box-shadow: 0px -4px 6px 0px rgba(0, 78, 235, 0.06);
}
</style>
