<template>
  <div id="app">
    <router-view />
    <bottom></bottom>
  </div>
</template>
<script>
import bottom from '@/components/bottom.vue'
export default {
  components: {
    bottom
  },
}
</script>
<style lang="scss">
body,
html {
  padding: 0;
  margin: 0;
}
</style>
