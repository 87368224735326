import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)
const VueRouterPush = Router.prototype.push
Router.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}
export default new Router({
  mode: "history",
  base: 'ClientApp',
  // 添加 scrollBehavior 方法
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes: [
    {
      path: '/',
      component: resolve => require(['@/views/PageView'], resolve),
      children: [
        {
          path: '/',
          redirect: '/phoneLogin',
          component: resolve => require(['@/views/phoneLogin'], resolve),
        },
        // {
        //   path: '/',
        //   redirect: '/uploadVideo',
        //   component: resolve => require(['@/views/uploadVideo'], resolve),
        // },
        {
          path: '/uploadVideo',
          name: 'uploadVideo',
          component: resolve => require(['@/views/uploadVideo'], resolve),
          meta: {
            title: '上传视频'
          }
        },
        {
          path: '/phoneLogin',
          name: 'phoneLogin',
          component: resolve => require(['@/views/phoneLogin'], resolve),
          meta: {
            title: '登录'
          }
        },
        {
          path: '/register',
          name: 'register',
          component: resolve => require(['@/views/register'], resolve),
          meta: {
            title: '注册',
            keepAlive: true
          }
        },
        {
          path: '/homePage',
          name: 'homePage',
          component: resolve => require(['@/views/homePage'], resolve),
          meta: {
            title: '首页'
          }
        },
        {
          path: '/user_settings',
          name: 'userSettings',
          component: resolve => require(['@/views/userSettings'], resolve),
          meta: {
            title: '用户设置'
          }
        },
        {
          path: '/phoneVerification',
          name: 'phoneVerification',
          component: resolve => require(['@/views/phoneVerification'], resolve),
          meta: {
            keepAlive: true
          }
        },
        {
          path: '/setPassword',
          name: 'setPassword',
          component: resolve => require(['@/views/setPassword'], resolve),
          meta: {
            title: '设置密码',
            keepAlive: true
          }
        },

        {
          path: '/bindEmail',
          name: 'bindEmail',
          component: resolve => require(['@/views/bindEmail'], resolve),
          meta: {
            title: '绑定邮箱',
            keepAlive: true
          }
        },
        {
          path: '/waitingForReview',
          name: 'waitingForReview',
          component: resolve => require(['@/views/waitingForReview'], resolve),
          meta: {
            title: '手写签名',
            keepAlive: true
          }
        },
        {
          path: '/uploadIdCard',
          name: 'uploadIdCard',
          component: resolve => require(['@/views/uploadIdCard'], resolve),
          meta: {
            title: '上传身份证',
            keepAlive: true
          }
        },
        {
          path: '/handwrittenSignature',
          name: 'handwrittenSignature',
          component: resolve => require(['@/views/handwrittenSignature'], resolve),
          meta: {
            keepAlive: true
          }
        },
        {
          path: '/renlian',
          name: 'renlian',
          component: resolve => require(['@/views/renlian'], resolve),
        },
        // 外汇详情
        {
          path: '/freignDetails',
          name: 'freignDetails',
          component: resolve => require(['@/views/freignDetails'], resolve),
          meta: {
            title: '外汇详情'
          }
        },
        // 修改手机号
        {
          path: '/changePhone',
          name: 'changePhone',
          component: resolve => require(['@/views/changePhone'], resolve),
          meta: {
            title: '修改手机号',
            keepAlive: false
          }
        },
        // 新手机号
        {
          path: '/newPhone',
          name: 'newPhone',
          component: resolve => require(['@/views/newPhone'], resolve),
          meta: {
            title: '新手机号',
            keepAlive: true
          }
        },
        // 修改密码
        {
          path: '/changePassword',
          name: 'changePassword',
          component: resolve => require(['@/views/changePassword'], resolve),
          meta: {
            title: '修改密码',
            keepAlive: true
          }
        },
        // 设置新的密码
        {
          path: '/newPassword',
          name: 'newPassword',
          component: resolve => require(['@/views/newPassword'], resolve),
          meta: {
            title: '新密码',
            keepAlive: true
          }
        },
        // 申请转出
        {
          path: '/transferRequest',
          name: 'transferRequest',
          component: resolve => require(['@/views/transferRequest'], resolve),
          meta: {
            title: '申请转出',
            keepAlive: true
          }
        },
        //转出列表
        {
          path: '/transferList',
          name: 'transferList',
          component: resolve => require(['@/views/transferList'], resolve),
          meta: {
            title: '转出列表',
            keepAlive: true
          }
        },
        //转出列表
        {
          path: '/transferDetail',
          name: 'transferDetail',
          component: resolve => require(['@/views/transferDetail'], resolve),
          meta: {
            title: '列表详情',
            keepAlive: true
          }
        },
        {
          path: '/mySettings',
          name: 'mySettings',
          component: resolve => require(['@/views/mySettings'], resolve),
          meta: {
            title: '用户设置',
            keepAlive: true
          }
        },
        {
          path: '/changeEmail',
          name: 'changeEmail',
          component: resolve => require(['@/views/changeEmail'], resolve),
          meta: {
            title: '修改邮箱',
            keepAlive: false
          }
        },
      ]
    }
  ]
})