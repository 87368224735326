import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate';
// Vuex 状态管理的 类似本地存储很像
// Vuex 组件之间的通信 不需要考虑组件的关系了
Vue.use(Vuex)

export default new Vuex.Store({
  state: {// 存储的状态管理
    status: 1,
    MobilePhone: '',
    EnrollToken: '',
  },
  getters: {// 定义方法去对状态管理的值进行处理 类似计算属性
  },
  mutations: {// 定义方法去修改状态 唯一修改状态的途径
    // 定义方法 默认的第一个参数 就是state对象
    setMobilePhone (state, mobilePhone) {
      state.MobilePhone = mobilePhone;
    },
    setEnrollToken (state, EnrollToken) {
      state.EnrollToken = EnrollToken;
    },
  },
  plugins: [createPersistedState()],
  actions: {// 类似mutations 但不能直接去修改状态 也需要mutations的支持
  },
  modules: {// 模块
  }
})
